import color from 'color';

const coalGrey = '#211D17';
const laasPurple = '#4D2A6F';
const systemError = '#B74849';

const mixWithWhite = (code: string, alpha: number) =>
  color(code)
    .mix(color('#fff'), 1 - alpha)
    .hex();

const systemSuccess = '#DAE5CB';

const laasLightBg = '#F5F4F7';
const systemOk = '#439C8B';
const indianSummerNotAA = '#D56048';
const rose = '#E9C9C3';
const roseMediumLight = '#EFD8D3';
const chartGreen = '#8CC24C';
const chartRed = '#E26C6E';

export const brandColors = {
  white: 'white',
  indianSummerNotAA,
  indianSummerNotAA30: mixWithWhite(indianSummerNotAA, 0.3),
  indianSummer: '#AC4E39',
  coalGrey,
  coalGrey8: mixWithWhite(coalGrey, 0.08),
  coalGrey10: mixWithWhite(coalGrey, 0.1),
  coalGrey20: mixWithWhite(coalGrey, 0.2),
  coalGrey50: mixWithWhite(coalGrey, 0.5),
  coalGrey70: mixWithWhite(coalGrey, 0.7),
  forestGreen5: '#F5F7F6',
  forestGreen15: '#DFE8E6',
  grey: '#E5E5E5',
  laasLightBg,
  laasLightBg90rgba: color(laasLightBg).alpha(0.9).toString(),
  laasPurple,
  laasPurple5: mixWithWhite(laasPurple, 0.05),
  laasPurple5Alpha: color(laasPurple).alpha(0.05).toString(),
  laasPurple10: mixWithWhite(laasPurple, 0.1),
  laasPurple15: mixWithWhite(laasPurple, 0.15),
  laasPurple15Alpha: color(laasPurple).alpha(0.15).toString(),
  laasPurple20: mixWithWhite(laasPurple, 0.2),
  laasPurple30: mixWithWhite(laasPurple, 0.3),
  laasPurple40: mixWithWhite(laasPurple, 0.4),
  laasPurple50: mixWithWhite(laasPurple, 0.5),
  laasPurple60: '#907BA6',
  laasPurple80: mixWithWhite(laasPurple, 0.8),
  laasPurple90: mixWithWhite(laasPurple, 0.9),
  lightPurpleBorderAA: '#9F8BB1',
  lightGreyBorderAA: '#99938F',
  rose,
  rose50: mixWithWhite(rose, 0.5),
  rose80: mixWithWhite(rose, 0.8),
  roseMediumLight,
  systemError,
  systemError20: mixWithWhite(systemError, 0.2),
  systemError30: mixWithWhite(systemError, 0.3),
  systemError05: mixWithWhite(systemError, 0.05),
  systemSuccess,
  systemOk,
  systemOk30: mixWithWhite(systemOk, 0.3),
  chartGreen,
  chartGreen20: mixWithWhite(chartGreen, 0.2),
  chartRed,
  chartRed10: mixWithWhite(chartRed, 0.1),
  chartYellow: '#EBC84E',
  chartYellowNotAA: '#ECCD5F',
  yellowNotAA: '#ECCD5F',
};

export const chartColors = {
  green: brandColors.chartGreen,
  yellow: brandColors.chartYellow,
  red: chartRed,
  brand: brandColors.laasPurple40,
  grey: brandColors.coalGrey20,
};

export const statusColors = {
  purple: brandColors.laasPurple20,
  yellow: brandColors.yellowNotAA,
  red: brandColors.systemError,
  green: brandColors.systemOk,
  grey: brandColors.coalGrey20,
};

export const interactionColors = {
  elementHover: `rgba(0, 0, 0, 0.03)`,
};

export const animationDurationNumbers = {
  short: 100,
  medium: 225,
  long: 275,
};

export const animationDuration = {
  short: `${animationDurationNumbers.short}ms`,
  medium: `${animationDurationNumbers.medium}ms`,
  long: `${animationDurationNumbers.long}ms`,
};

export const breakpoints = {
  extraLarge: 1200, // 1025px - 1200px
  large: 1024, // 769px - 1024px
  medium: 768, //  481px - 768px
  small: 480, // 320-480px
};

export const menuHeight = {
  mobile: 88,
  desktop: 88,
};

export const zIndex = {
  content: 1,
  nav: 10,
  modal: 100,
  tooltip: 1000,
};

export const roundImageSizeNumbers = {
  small: 48,
  medium: 72,
  large: 96,
};

export const roundImageSize = {
  small: `${roundImageSizeNumbers.small}px`,
  medium: `${roundImageSizeNumbers.medium}px`,
  large: `${roundImageSizeNumbers.large}px`,
};

export const mediaQuery = {
  small: `(max-width: ${breakpoints.small}px)`,
  widerThanSmall: `(min-width: ${breakpoints.small + 1}px)`,
  medium: `(max-width: ${breakpoints.medium}px)`,
  widerThanMedium: `(min-width: ${breakpoints.medium + 1}px)`,
  large: `(max-width: ${breakpoints.large}px)`,
  widerThanLarge: `(min-width: ${breakpoints.large + 1}px)`,
  extraLarge: `(max-width: ${breakpoints.extraLarge}px)`,
  widerThanExtraLarge: `(min-width: ${breakpoints.extraLarge + 1}px)`,
};

export const filters = {
  dropShadow: 'drop-shadow(0px 4px 12px rgba(77, 42, 111, 0.05))',
};

export const contentMaxWidth = 1200;
export const contentMaxWidthPx = `${contentMaxWidth}px`;

export const toastNotificationAutoCloseMs = 3000;

export const tooltipMaxWidth = 480;
