import { InputHTMLAttributes } from 'react';
import styled from '@emotion/styled';
import { Check } from '../icons';
import { brandColors } from '../constants';

const Container = styled.div<{ disabled: boolean }>`
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  text-align: left;

  font-family: Mulish;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 16px;

  color: ${(props) =>
    props.disabled ? brandColors.coalGrey50 : brandColors.coalGrey};

  input,
  label {
    cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  }

  .checkmark {
    display: none;
  }

  input:checked ~ .checkmark {
    display: block;
  }
`;

const StyledInput = styled.input`
  width: 100%;
  height: 100%;
  appearance: none;
  outline: none;
  border: 1px solid ${brandColors.laasPurple};
  box-sizing: border-box;
  border-radius: 4px;

  :disabled {
    border: 1px solid ${brandColors.coalGrey20};
  }

  :checked:disabled {
    background-color: ${brandColors.coalGrey20};
    border: none;
  }

  :checked {
    background-color: ${brandColors.laasPurple};
    border: none;
  }

  :focus,
  :active {
    box-shadow: 0 0 0 2px ${brandColors.laasPurple60};
  }
`;

const Checkmark = styled(Check)`
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  pointer-events: none;
  fill: ${brandColors.white};
`;

const InputContainer = styled.div`
  width: 24px;
  height: 24px;
  position: relative;
  flex-shrink: 0;
`;

const Label = styled.label`
  padding-left: 16px;
  position: relative;
`;

const TouchArea = styled.div`
  position: absolute;
  top: -12px;
  left: calc(-12px - 24px);
  right: -12px;
  bottom: -12px;
  border-radius: 4px;
`;

export type CheckboxProps = InputHTMLAttributes<HTMLInputElement>;

export const Checkbox = ({
  children,
  className,
  onClick,
  ...inputProps
}: CheckboxProps) => (
  <Container
    disabled={!!inputProps.disabled}
    className={className}
    onClick={onClick}
  >
    <InputContainer>
      <StyledInput {...inputProps} type="checkbox" />
      <Checkmark className="checkmark" />
    </InputContainer>
    <Label htmlFor={inputProps.id}>
      {inputProps.id ? <TouchArea /> : null}
      {children}
    </Label>
  </Container>
);
